<template>
  <div
    v-if="user.email"
    class="the-workspaces"
  >
    <div class="the-workspaces__header">
      <h1 data-testid="workspaces__title">
        Your Workspaces
      </h1>
      <p
        class="the-workspaces__subheader"
        data-testid="workspaces__subtitle"
      >
        You’re looking at workspaces for {{ user.email }}.
        <br>
        Not seeing what you’re looking for?
        <a
          data-testid="workspaces__logout"
          @click="logout(user, $hubApiBaseUrl, $rendersApiBaseUrl)"
        >Try a different email</a>
      </p>
    </div>
    <div class="the-workspaces__list">
      <a
        v-for="workspace in workspaces"
        :key="workspace.name"
        @click="activateWorkspace(workspace)"
      >
        <div
          class="the-workspaces__item"
          data-testid="workspaces__list-item"
          :style="{ width: `${100 / workspaces.length - workspaces.length}vw` }"
        >
          <div
            class="the-workspaces__item-image"
            data-testid="workspaces__item-image"
            :style="{
              backgroundImage: `url('${workspace.image}')`
            }"
          />
          <div class="the-workspaces__item-title">
            <h2 data-testid="workspaces__item-title">{{ workspace.name }}</h2>
          </div>
          <div class="the-workspaces__item-action">
            <i class="el-icon-right" />
          </div>
        </div>
      </a>
    </div>
    <div
      class="the-workspaces__logo"
      data-testid="workspaces__logo"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import { logout } from '@/services/utils'

export default {
  name: 'TheWorkspaces',

  /**
   * @param {object}   to
   * @param {object}   from
   * @param {Function} next
   */
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      if (isEmpty(vm.$store.getters.getCognitoUserData)) {
        await vm.$store.dispatch('fetchCognitoUserData')
      }

      vm.$store.dispatch('setDarkMode', true)

      return to
    })
  },

  /**
   * @param {object}   to
   * @param {object}   from
   * @param {Function} next
   */
  beforeRouteLeave (to, from, next) {
    this.setDarkMode(false)
    next()
  },

  computed: {
    ...mapGetters({
      user: 'getCognitoUserData'
    }),

    /**
     * @returns {object[]}
     */
    workspaces () {
      return this.user.workspaces
    }
  },

  methods: {
    ...mapActions(['setDarkMode', 'setActiveWorkspace']),

    logout: logout,

    /**
     *
     * @param {object} workspace
     */
    activateWorkspace (workspace) {
      this.setActiveWorkspace(workspace)
      this.$router.push({ name: 'DesignHUB' })
    }
  }
}
</script>

<style lang="scss" scoped>
$container-padding: 20px;
$content-height: 75vh;
$item-horizontal-margin: 0.5vw;
$border-radius: 25px;
$item-action-size: 32px;
$item-max-size: 49vw;

.the-workspaces {
  flex-direction: column;
  height: 100%;
  padding: $container-padding;
}

.the-workspaces__header {
  text-align: center;
}

.the-workspaces__subheader a:hover {
  color: $blue;
  cursor: pointer;
}

.the-workspaces__list {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  height: $content-height;
  margin: spacing(2.5) 0 spacing(1.3);
}

.the-workspaces__item-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: all 0.5s;
}

.the-workspaces__item {
  position: relative;
  max-width: $item-max-size;
  height: 100%;
  margin: 0 $item-horizontal-margin;
  overflow: hidden;
  border-radius: $border-radius;
  cursor: pointer;

  &:hover {
    box-shadow: $shadow-default;
  }

  &:hover .the-workspaces__item-image {
    transform: scale(1.2);
  }
}

.the-workspaces__logo {
  position: absolute;
  bottom: spacing(4);
  width: 100%;
  height: spacing(4);
  background-image: url('~@/assets/images/stitch-logo.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-origin: content-box;
}

.the-workspaces__item-title {
  @include text-ellipsis;
  @include text-body-medium;

  position: absolute;
  padding-top: spacing(4);
  padding-left: spacing(4);
}

.the-workspaces__item-action {
  position: absolute;
  right: spacing(4);
  bottom: spacing(4);
  color: $white;
  font-size: $item-action-size;
}

.dark-mode .the-workspaces__logo {
  background-image: url('~@/assets/images/stitch-logo_white.svg');
}
</style>
